import React from 'react';
import MoreInput from '../MoreInput';

const Buyers = ({buyer}) => {
    return (
        <>
            <p className='text-xl my-2'>{buyer}</p>
            <hr/>
            </>
    );
};

export default Buyers;